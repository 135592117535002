<template>
    <section>
        <b-card>
        <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Clienti </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="$router.push('/customer/list')"> Elenco Clienti</b-breadcrumb-item>
            <b-breadcrumb-item active> Dettaglio Cliente</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">

      <h2 class="text-primary-light">{{customer.cliente_nome}} {{customer.cliente_cognome}} - {{customer.cliente_tipologia}} <span v-if="customer.cliente_partitaiva">(P.IVA: {{customer.cliente_partitaiva}})</span> </span></h2>

      <hr>

      <span class="text-primary-light font-weight-bold">Dati Anagrafici</span>
            <b-row class="mt-1">

                <b-col md="2">
                    <b-form-group label="Codice Fiscale">
                        <b-form-input :value="customer.cliente_codicefiscale" :plaintext="!$route.params.edit">
                            
                        </b-form-input>
                    </b-form-group>
          </b-col>

          <b-col md="2">
              <b-form-group label="Data di nascita">
                  <b-form-input :value="customer.cliente_nascita_data" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>

          <b-col md="2">
              <b-form-group label="Indirizzo">
                  <b-form-input :value="customer.cliente_indirizzo" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>

           <b-col md="2">
              <b-form-group label="Nazione">
                  <b-form-input :value="customer.cliente_nazione" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>

          <b-col md="2">
              <b-form-group label="Città">
                  <b-form-input :value="customer.cliente_citta" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>

          <b-col md="2">
              <b-form-group label="Provincia">
                  <b-form-input :value="customer.cliente_provincia" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>

          <b-col md="2">
              <b-form-group label="CAP">
                  <b-form-input :value="customer.cliente_cap" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>
      </b-row>


      <hr>

      <span class="text-primary-light font-weight-bold">Informazioni di contatto</span>

      <b-row class="mt-1">

          <b-col md="3">
              <b-form-group label="Indirizzo E-mail">
                  <b-form-input :value="customer.cliente_email" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>

          <b-col md="2">
              <b-form-group label="Telefono">
                  <b-form-input :value="customer.cliente_telefono" :plaintext="!$route.params.edit">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>

           <b-col md="2">
              <b-form-group label="Cellulare">
                  <b-form-input :plaintext="!$route.params.edit" v-model="customer.cliente_cellulare">
                      
                  </b-form-input>
              </b-form-group>
          </b-col>
      </b-row>

      <div class="text-right">
      <b-button variant="primary" v-if="$route.params.edit" @click="editCustomerDetails">
        Salva Modifiche
      </b-button>
      </div>

      </div>

      

      </b-card>
    </section>
</template>

<script>
    import {
             BButton, 
             BRow, 
             BCol, 
             BBreadcrumb, 
             BBreadcrumbItem, 
             BCard, 
             BFormGroup, 
             BFormInput
            } 
    from "bootstrap-vue";

    import {Requests} from "@/api";

    export default {
        components: {
            BButton,
            BRow,
            BCol,
            BBreadcrumb,
            BBreadcrumbItem,
            BCard,
            BFormGroup,
            BFormInput,
        },

        data: () => ({
            customer: {},

            //Helpers

            loading: false,
        }),

        created() {
            this.getCustomerDetails();
        },  

        methods: {
            
            async getCustomerDetails() {
                this.loading = false;

                let request_data = new FormData();

                request_data.append('id_customer', this.$route.params.id);

                try {
                    const response = await Requests.getCustomerDetails(request_data);
                    this.customer = response[0].db_customer_details.payload;
                } catch(err) {
                    console.debug(err);
                }
            },

            async editCustomerDetails() {
                this.loading = false;

                let request_data = new FormData();

                request_data.append('id_customer', this.$route.params.id);
                request_data.append('cliente_ragionesociale', this.customer.cliente_ragionesociale);
                request_data.append('cliente_nome', this.customer.cliente_nome);
                request_data.append('cliente_cognome', this.customer.cliente_cognome);
                request_data.append('cliente_indirizzo', this.customer.cliente_indirizzo);
                request_data.append('cliente_citta', this.customer.cliente_citta);
                request_data.append('cliente_cap', this.customer.cliente_cap);
                request_data.append('cliente_provincia', this.customer.cliente_provincia);
                request_data.append('cliente_nazione', this.customer.cliente_nazione);
                request_data.append('cliente_codicefiscale', this.customer.cliente_codicefiscale);
                request_data.append('cliente_partitaiva', this.customer.cliente_partitaiva);
                request_data.append('cliente_tipologia', this.customer.cliente_tipologia);
                request_data.append('cliente_telefono', this.customer.cliente_telefono);
                request_data.append('cliente_cellulare', this.customer.cliente_cellulare);
                request_data.append('cliente_email', this.customer.cliente_email);
                request_data.append('cliente_sesso', this.customer.cliente_sesso);
                request_data.append('cliente_nascita_data', this.customer.cliente_nascita_data);
                request_data.append('cliente_nascita_luogo', this.customer.cliente_nascita_luogo);

                try {
                    await Requests.editCustomerDetails(request_data);
                     this.$swal.fire({
                                    icon: 'success',
                                    title: "Modifiche Salvate!",
                                    customClass: {
                                        title: "text-primary-light",
                                        confirmButton: 'btn btn-primary',
                                    }
                                })
                } catch(err) {
                    this.$swal.fire({
                                    icon: 'error',
                                    title: "Ops...",
                                    text: err.response.data[0].db_customer_edit.feedback,
                                    customClass: {
                                        title: "text-primary-light",
                                        confirmButton: 'btn btn-primary',
                                    }
                                })
                    console.debug(err.response.data);
                }
            },


        }
    }
</script>